<template>
  <div>
    <btn @click="frontendErr()">frontend</btn>
    <btn @click="backendErr()">backend</btn>
  </div>
</template>

<script>
import { unauthClient } from "@/api";

export default {
  methods: {
    frontendErr() {
      throw "testing error";
    },
    async backendErr() {
        await unauthClient.post('/test-err/');
    }
  }
};
</script>
